









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.share_profile_with_rtl {
  text-align: right;
  .el-form-item__label {
    width: 100%;
  }
}
.for_tasks_editing_page {
  .el-collapse-item__header {
    padding: 2.5rem 0;
  }
}

.add_strategy_dialog_task_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
  &.is_rtl {
    .el-dialog__header {
      text-align: right !important;
    }
  }
  .form_with_rtl {
    .el-form-item__label {
      width: 100%;
      text-align: right;
    }
    .el-input__inner,
    .el-textarea__inner {
      text-align: right;
    }
  }
}

//? tooltip style
.el-tooltip__popper.is-dark {
  // background-color: #fff !important;
  // color: #707070;
  // border-radius: 5px;
  // box-shadow: 0 1px 12px rgba(0, 0, 0, 0.2);
  // .popper__arrow {
  //   border-top-color: #fff;
  //   &::after {
  //     border-top-color: #fff;
  //   }
  // }
  @media print {
    display: none !important;
  }
}
